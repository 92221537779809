import React from "react"
import { useTranslation } from "react-i18next"

import SinglePublisher from './single-publisher'

import {
  makeStyles,
  Container,
  Grid,
  Box,
  Button,
} from "@material-ui/core"
import SEO from "./seo"

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loadmoreBtnWrap: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
}))

export default function PublishersList({ props, publishers }) {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const [loadedPublishers, setloadedPublishers] = React.useState(9)

  const handleLoadMore = () => {
    setloadedPublishers(loadedPublishers + 8)
  }

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <SEO title="Publishers list" lang={i18n.language} />
      <Grid container spacing={4}>
        {publishers.slice(0, loadedPublishers).map((item, key) => (
          <SinglePublisher publishers={item} key={key} />
        ))}
      </Grid>
      {publishers.length > loadedPublishers && (
        <Box className={classes.loadmoreBtnWrap} p={2}>
          <Button variant="outlined" color="primary" onClick={handleLoadMore}>
            {t("bookList.loadMoreBtn")}
          </Button>
        </Box>
      )}
    </Container>
  );
}