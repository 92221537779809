import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import PublisherList from "../components/publisher-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

// import CategoryBg from "../../static/images/mainBg.jpg"
import PublishersImage from "../../static/images/publishers/books.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  PublishersBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}))

const Publishers = ({ data, pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "publishers",
        `/${i18n.language}/publishers`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "publishers",
          `/${i18n.language}/publishers`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/publishers`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("publishers.PageName"),
    },
  ]

  const publishers = []
  for (let book of data.allStrapiPublishers.nodes) {
    let objCopy = Object.assign({}, book)
    publishers.push(objCopy)
  }

  return (
    <>
      <SEO title={t("publishers.PageName")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("publishers.PageName")} image={PublishersImage} />
      <Container className={`${classes.fullWidth} ${classes.PublishersBg}`}>
        <PublisherList publishers={publishers} />
      </Container>
    </>
  )
}
export default Publishers

export const query = graphql`
  query Publishers {
    allStrapiPublishers {
      nodes {
        id
        name
        name_ar
        name_tr
        country
        strapiId
        description
        description_ar
        description_tr
        image {
          publicURL
        }
      }
    }
  }
`
