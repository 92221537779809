import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import { makeStyles, Grid, Box, Typography } from "@material-ui/core"

import PublishersImage from "../../static/images/publishers/books.svg"

const useStyles = makeStyles(theme => ({
  publisherItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      textAlign: "center",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      maxWidth: 76,
    },
    "& p": {
      margin: "10px auto 0",
      fontSize: "1rem",
      color: "#fff",
    },
    "&:nth-child(odd) .publisherItemBox": {
      backgroundColor: "#003e96",
    },
    "&:nth-child(even) .publisherItemBox": {
      backgroundColor: "#018fff",
    },
  },
  publisherItemBox: {
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
}))

const Publishers = ({ publishers }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  return (
    <Grid item xs={12} sm={6} lg={4} md={4} className={classes.publisherItem}>
      <Link to={`/${i18n.language}/publishers/${publishers.strapiId}`}>
        <Box className={`${classes.publisherItemBox} publisherItemBox `}>
          <img
            p={3}
            src={
              publishers.image ? publishers.image.publicURL : PublishersImage
            }
            alt={publishers.name}
          />
          <Typography variant="h5" component="p">
            {i18n.language === "ar"
              ? publishers.name_ar
              : i18n.language === "tr"
              ? publishers.name_tr
              : i18n.language === "en" && publishers.name}
          </Typography>
        </Box>
      </Link>
    </Grid>
  )
}
export default Publishers
